var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"full-info"},[_c('span',{staticClass:"link",on:{"click":_vm.toggle}},[_vm._v("("+_vm._s(_vm.item.similar_tasks_count)+" Matches)")]),_c('app-dialog',{staticClass:"modal",attrs:{"max-width":"900px"},model:{value:(_vm.showing),callback:function ($$v) {_vm.showing=$$v},expression:"showing"}},[_c('v-card',{staticClass:"card"},[_c('v-card-title',{staticClass:"card__title"},[_c('span',[_vm._v("Similar tasks")]),_c('btn-icon',{on:{"click":_vm.toggle}},[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"card__text"},[_c('app-table',{staticClass:"app-table-new v-data-table theme--light",attrs:{"headers":_vm.customHeaders,"expanded":_vm.expanded,"list":_vm.list,"item-key":"id","hide-default-footer":"","loading":_vm.loading,"show-select":"","mobile-breakpoint":NaN,"hide-default-header":"","multiExpand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var select = ref.select;
var isSelected = ref.isSelected;
var index = ref.index;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('item',{key:item.id,attrs:{"item":item,"isSelected":isSelected,"select":select,"index":index,"expand":expand,"isExpanded":isExpanded,"activeItems":_vm.activeItems},on:{"onGoToEdit":_vm.goToEdit,"change:status":function($event){return _vm.$emit('change:status', $event)}},scopedSlots:_vm._u([{key:"select",fn:function(){return [_c('td',[_c('v-checkbox',{key:index,staticClass:"mt-0 pt-0",attrs:{"hide-details":""},on:{"change":select},model:{value:(isSelected),callback:function ($$v) {isSelected=$$v},expression:"isSelected"}})],1)]},proxy:true},{key:"similar",fn:function(){return [_c('span')]},proxy:true},{key:"status",fn:function(){return [_c('span')]},proxy:true},{key:"log-send",fn:function(){return [_c('span')]},proxy:true}],null,true)})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pl-0 pr-0 app-table__expand",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"task-app-table"},[_vm._l((item.applications),function(application){return [_c('application-item',{key:application.application_id,attrs:{"app":application}})]})],2)])]}}]),model:{value:(_vm.activeItems),callback:function ($$v) {_vm.activeItems=$$v},expression:"activeItems"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('button',{staticClass:"button",attrs:{"disabled":_vm.buttonDisabled},on:{"click":_vm.mergeTasks}},[_vm._v("Merge")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }