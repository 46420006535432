<template lang="pug">
  span.full-info
    span(@click="toggle").link ({{ item.similar_tasks_count}} Matches)
    app-dialog(v-model="showing" max-width="900px").modal
      v-card.card
        v-card-title.card__title
          span Similar tasks
          btn-icon(@click="toggle")
            v-icon(small color="black") mdi-close

        v-card-text.card__text
          app-table(
            v-model="activeItems"
            :headers="customHeaders"
            :expanded.sync="expanded"
            :list="list"
            item-key="id"
            hide-default-footer
            multi-sort=false
            :loading="loading"
            show-select
            :mobile-breakpoint='NaN'
            hide-default-header
            multiExpand
            class="app-table-new v-data-table theme--light"
          )
            template(v-slot:item="{item, select, isSelected, index, expand, isExpanded}")
              item(
                :item="item"
                :isSelected="isSelected"
                :select="select"
                :index="index"
                :expand="expand"
                :isExpanded="isExpanded"
                :key="item.id"
                :activeItems="activeItems"
                @onGoToEdit="goToEdit"
                @change:status="$emit('change:status', $event)"
              )
                template(#select)
                  td
                    v-checkbox(
                      hide-details
                      v-model="isSelected"
                      :key="index"
                      @change="select"
                    ).mt-0.pt-0
                template(#similar)
                  span
                template(#status)
                  span
                template(#log-send)
                  span

            template( v-slot:expanded-item="{ headers, item }")
              td(:colspan="headers.length").pl-0.pr-0.app-table__expand
                div.task-app-table
                  template(v-for="application in item.applications")
                    application-item(:app="application" :key='application.application_id')
        v-card-actions
          v-spacer
          button(:disabled="buttonDisabled" @click="mergeTasks").button Merge
</template>

<script>
import showingMixin from '@/mixins/showing.mixin'
import api from "@/services/api/api-taskApllications"
import Svc from "../core/taskApplications-service"
import errorsMixin from '@/mixins/errors.mixin'
import { TASK_SIMILAR_APPLICATIONS_HEADERS } from '../core/taskApplications-const'

export default {
  mixins: [showingMixin, errorsMixin],

  props: {
    item: Object
  },

  data: () => ({
    activeItems: [],
    loading: false,
    headers: TASK_SIMILAR_APPLICATIONS_HEADERS,
    expanded: [],
    list: []
  }),

  computed: {
    customHeaders() {
      let headers = this.headers.map(item => {
        return {...item, sortable: false}
      })
      return headers
    },
    buttonDisabled() {
      return this.activeItems.length < 2
    }
  },

  created() {
    this.activeItems = [this.item]
  },

  methods: {
    async loadList() {
      try {
        this.loading = true
        let res = await new Svc(api).similarTasks(this.item.id)
        this.list = [this.item, ...res]
      } catch (error) {
        console.log(error)
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    goToEdit({expand, isExpanded}) {
      expand(!isExpanded)
    },

    async mergeTasks() {
      try {
        this.loading = true
        await new Svc(api).mergeTasks(this.activeItems)
        this.$notify({
          type: 'success',
          text: 'Merged'
        })
        this.toggle()
        this.$emit('merged')
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    }
  },

  watch: {
    showing() {
      if (this.showing) this.loadList()
    }
  },

  components: {
    appDialog: () => import('./NewDialog.vue'),
    btnIcon: () => import('@/components/global/BtnIcon.vue'),
    appTable: () => import('@/components/global/GroupedTable.vue'),
    item: () => import('./TaskApplicationsItem.vue'),
    applicationItem: () => import('./TaskApplicationApp.vue'),
    btn: () => import('@/components/global/BtnCustom.vue')
  }
}
</script>

<style lang="scss">
.button {
  padding: 8px 16px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 11px;
  color: #feffff;
  background-color: #007aff;
  font-family: $font;
  font-weight: bold;

  &:hover {
    opacity: .8;
  }

  &[disabled] {
    opacity: .3;
    cursor: not-allowed;
  }
}
</style>
